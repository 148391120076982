// src/main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router'; // وارد کردن تنظیمات Vue Router
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

Vue.config.productionTip = false;

new Vue({
  router, // اضافه کردن Vue Router به نمونه Vue
  render: h => h(App),
}).$mount('#app');
