<template>
  <div id="app">
    <router-view></router-view> <!-- نمایش محتوای مسیر فعلی -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* استایل‌های عمومی */
</style>
