import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/Login.vue'; // صفحه ورود
import Home from '../components/Home.vue'; // صفحه اصلی

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true }, // نیاز به ورود برای نمایش
    },
    {
      path: '*',
      redirect: '/login', // مسیر پیش‌فرض برای مسیرهای ناشناخته
    },
  ],
});

// گارد برای مسیرهایی که نیاز به ورود دارند
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('loggedIn');
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
