<!-- src/components/Home.vue -->

<template>
  <div class="container mt-5">
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-4">
      <a class="navbar-brand" href="#">سامانه هتلینگ</a>
      <div class="collapse navbar-collapse">
        <form class="form-inline my-2 my-lg-0 ml-auto">
          <input class="form-control mr-sm-2" type="search" placeholder="جستجو" aria-label="جستجو">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit">جستجو</button>
        </form>
      </div>
    </nav>

    <div v-if="loading" class="alert alert-info">در حال بارگذاری...</div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div v-if="!loading && !error" class="row">
      <div v-for="item in data" :key="item.CustomerGuid" class="col-sm-6 col-md-4 col-lg-3" style="margin-bottom: 10px;">
         <a :href="item.AdminComment"  class="text-decoration-none">
        <div class="card shadow-lg"> <!-- کلاس shadow-lg برای سایه بزرگ -->
          <img :src="item.SystemName" class="card-img-top" alt="تصویر">
          <div class="card-body">
            <h5 class="card-title">{{ item.Company }}</h5>
           
          </div>
        </div>
         </a>
      </div>
    </div>

    <div v-if="!loading && !error && !data.length" class="alert alert-warning mt-3">هیچ داده‌ای یافت نشد</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      data: [], // داده‌های دریافتی
      loading: true, // وضعیت بارگذاری
      error: null, // خطاهای احتمالی
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
       const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://jaygahforosh.ir/apiPos/GetAllVendorUser', {
          headers: {
            'Authorization': `Bearer ${token}`, // اضافه کردن توکن به هدر
          },
        });
        if (response.data.response === "ok") {
          this.data = response.data.customers; // ذخیره داده‌ها در متغیر data
        } else {
          this.error = response.data.msg; // تنظیم پیام خطا
        }
      } catch (error) {
        this.error = 'خطا در دریافت اطلاعات'; // پیام خطای عمومی
      } finally {
        this.loading = false; // پایان وضعیت بارگذاری
      }
    },
  },
};
</script>

<style scoped>
.card-img-top {
  height: 200px; /* تنظیم ارتفاع تصویر */
   /* object-fit: cover;پوشش تصویر درون کارت */
}
.card-body {
  text-align: center; /* مرکز چیدمان متن درون کارت */
}
</style>
