<template>
  <div class="login-container">
    <h2>ورود به سیستم</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">نام کاربری</label>
        <input v-model="username" type="text" id="username" class="form-control" required>
      </div>
      <div class="form-group">
        <label for="password">رمز عبور</label>
        <input v-model="password" type="password" id="password" class="form-control" required>
      </div>
      <div class="form-group">
        <input v-model="rememberMe" type="checkbox" id="rememberMe">
        <label for="rememberMe">مرا به یاد بسپار</label>
      </div>
      <button type="submit" class="btn btn-primary">ورود</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
    };
  },
  methods: {
    async login() {
      if (this.username.length > 2 && this.password.length > 2) {
        const obj = {
          guest: false,
          username: this.username,
          password: this.password,
          remember_me: this.rememberMe,
        };

        try {
          const response = await axios.post('https://jaygahforosh.ir/token', obj, {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          });

          const token = response.data.access_token;
          this.handleSuccess(token);
        } catch (error) {
          alert('نام کاربری یا رمز عبور یافت نشد');
        }
      } else {
        alert('نام کاربری یا رمز عبور کوتاه است');
      }
    },
    handleSuccess(token) {
      
     // ذخیره توکن و هدایت به صفحه اصلی

      localStorage.setItem('token', token);
      localStorage.setItem('loggedIn', 'true');
        
      // بررسی مسیر فعلی
        if (this.$route.path !== '/home') {
    this.$router.replace('/')
      .catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
  }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 1em;
}
</style>
